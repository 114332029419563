import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import Logo from '../src/assets/TDA_Rubix-Cube.png'
import Navigation from '../src/components/Navigation.js';
import EventsChart from './components/EventsChart.js';

function App() {
  const [rounds, setRounds] = useState([]);
  const [pin, setPin] = useState('');
  const [accessGranted, setAccessGranted] = useState(false);
  const [rowsToAdd, setRowsToAdd] = useState('');

  useEffect(() => {
    const fetchAndInitialiseRounds = async () => {
      try {
        const response = await axios.get(`https://api.thedailyaus.com.au/api/rounds`);
        let fetchedRounds = response.data.rounds.map(round => ({
          ...round,
          date: round.date ? round.date.split('T')[0] : '' // Ensure date is in 'YYYY-MM-DD'
        }));

        // Initialise with fetched data
        setRounds(fetchedRounds);
      } catch (error) {
        console.error('Failed to fetch rounds:', error);
        alert('Failed to fetch initial rounds data. Please check the console for more details.');
      }
    };

    fetchAndInitialiseRounds();
  }, []);

  const handleChange = (index, field, value) => {
    const newRounds = [...rounds];
    // Directly assign the value to the field without splitting by commas here.
    newRounds[index][field] = value;
    setRounds(newRounds);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Process answers to ensure they are in the expected array format
    const processedRounds = rounds.map(round => ({
      ...round,
      answer: typeof round.answer === 'string' ? processAnswers(round.answer) : round.answer,
    }));

    try {
      const response = await axios.post('https://api.thedailyaus.com.au/api/rounds/update', { rounds: processedRounds });
      console.log('Rounds updated:', response.data);
      alert('Rounds updated successfully!');
    } catch (error) {
      console.error('Failed to update rounds:', error);
      alert('Failed to update rounds. Please check the console for more details.');
    }
};

// Ensures the answer string is split into an array of trimmed strings
const processAnswers = (answerString) => {
  return answerString.split(',').map(answer => answer.trim());
};

  const handlePinSubmit = (e) => {
    e.preventDefault();
    if (pin === '2026Bondi2026!') {
      setAccessGranted(true);
    } else {
      alert('Incorrect PIN');
      setPin('');
    }
  };

  const addRows = (numberOfRows) => {
    const newRowObjects = Array.from({ length: Number(numberOfRows) }, () => ({
      date: '',
      emojis: '',
      answer: '',
      hint: ''
    }));
    setRounds((prevRounds) => [...prevRounds, ...newRowObjects]);
    setRowsToAdd(''); // Reset input after adding rows
  };

  if (!accessGranted) {
    return (
      <div className="Security">
        <img className="logo" src={Logo} alt="TDA Rubix Cube" />
          <form onSubmit={handlePinSubmit}>
            <input
              type="password"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              placeholder="Enter PIN"
            />
            <button>Submit</button>
          </form>
      </div>
    );
  }

  return (
    <div className="App">
      <form onSubmit={handleSubmit}>
      <Navigation />
      <EventsChart />
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Emojis</th>
              <th>Answer</th>
              <th>Theme</th>
            </tr>
          </thead>
          <tbody>
            {rounds.map((round, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="date"
                    value={round.date || ''}
                    onChange={(e) => handleChange(index, 'date', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={round.emojis || ''}
                    onChange={(e) => handleChange(index, 'emojis', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={round.answer || ''}
                    onChange={(e) => handleChange(index, 'answer', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={round.hint || ''}
                    onChange={(e) => handleChange(index, 'hint', e.target.value)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
        <div className="utilitywrapper">
          <div className="ulitywrapper__add">
          <input
            type="number"
            value={rowsToAdd}
            onChange={(e) => setRowsToAdd(e.target.value)}
            placeholder="Number of rows"
          />
          <button type="button" onClick={() => addRows(rowsToAdd)}>Add</button>
          </div>
          <div className="ulitywrapper__add">
          <button type="submit">Save</button>
          </div>
        </div>
       </form>
    </div>
  );
}

export default App;

