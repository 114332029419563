// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Bar } from 'react-chartjs-2';
// import 'chart.js/auto';

// const EventsChart = () => {
//   const formatDate = (date) => {
//     return new Date(date).toISOString().split('T')[0];
//   };
//   // const endDate = formatDate(new Date()); // Today
//   // const endDate = formatDate(new Date(new Date().setHours(23, 59, 59, 999)));
//   // const startDate = formatDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)); // 7 days ago

//   // const startDate = formatDate(new Date(new Date().setHours(7, 0, 0, 0) - 7 * 24 * 60 * 60 * 1000)); 
//   // const endDate = formatDate(new Date(new Date().setHours(6, 59, 59, 999)));

//   // const [chartData, setChartData] = useState(null);
//   // const [start, setStart] = useState(startDate);
//   // const [end, setEnd] = useState(endDate);

//   useEffect(() => {
//     const fetchEventData = async () => {
//       if (!startDate || !endDate) return;
//       try {
//         const response = await axios.get('https://api.thedailyaus.com.au/api/events/aggregate');
//         const aggregateData = response.data;

//         const convertDateToISO = (dateStr) => {
//           const [day, month, year] = dateStr.split('-');
//           return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`; // Converts to YYYY-MM-DD
//         };

//         const uniqueDates = [...new Set(aggregateData.map(item => item._id.date))]
//         .map(date => convertDateToISO(date))
//                                 .filter(date => date >= startDate && date <= endDate);
//         const eventTypes = ['page_view', 'game_start', 'game_loss', 'game_win'];

//         const datasets = eventTypes.map(type => ({
//           label: type,
//           data: uniqueDates.map(date => {
//             const item = aggregateData.find(item => convertDateToISO(item._id.date) === date && item._id.eventType === type);
//             // const item = aggregateData.find(item => item._id.date === date && item._id.eventType === type);
//             return item ? item.count : 0;
//           }),
//           backgroundColor: generateBackgroundColor(type),
//         }));

//         setChartData({
//           labels: uniqueDates,
//           datasets,
//         });
//       } catch (error) {
//         console.error('Failed to fetch event data:', error);
//       }
//     };

//     fetchEventData();
//   }, [startDate, endDate]);

//   // Check if chartData is not null before rendering the chart
//   return (
//     <div className="section">
//       <h2>Analytics</h2>
//       <div>
//         <label>Start Date: </label>
//         <input type="date" value={start} onChange={(e) => setStart(e.target.value)} />
//         <label> End Date: </label>
//         <input type="date" value={end} onChange={(e) => setEnd(e.target.value)} />
//       </div>
//       {chartData ? (
//         <Bar data={chartData} options={chartOptions} />
//       ) : (
//         <p>Please select a date range to display the chart...</p> // Display a loading message or a loader
//       )}
//     </div>
//   );
// };

// const generateBackgroundColor = (eventType) => {
//   switch (eventType) {
//     case 'page_view':
//       return '#D4CEFD'; // Light purple
//     case 'game_start':
//       return '#FFDD00'; // Yellow
//     case 'game_loss':
//       return '#FF4747'; // Red
//     case 'game_win':
//       return '#2CA430'; // Green
//     default:
//       return '#D4CEFD'; // Default light purple if needed
//   }
// };

// const chartOptions = {
//   plugins: {
//     title: {
//       display: true,
//       text: 'Event Counts by Date',
//       color: 'white' // Title color
//     },
//     legend: {
//       labels: {
//         color: 'white' // Legend labels
//       }
//     }
//   },
//   scales: {
//     x: {
//       stacked: false,
//       ticks: {
//         color: 'white' // X-axis labels
//       },
//       grid: {
//         color: 'rgba(255, 255, 255, 0.5)' // Optional: Adjust grid line color
//       }
//     },
//     y: {
//       stacked: false,
//       beginAtZero: true,
//       ticks: {
//         color: 'white' // Y-axis labels
//       },
//       grid: {
//         color: 'rgba(255, 255, 255, 0.5)' // Optional: Adjust grid line color
//       }
//     }
//   }
// };

// export default EventsChart;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const EventsChart = () => {
  const formatDate = (date) => {
    return new Date(date).toISOString().split('T')[0];
  };

  const calculateDates = () => {
    const now = new Date();
    const currentHour = now.getHours();
    let tempEndDate = new Date();
    // Adjust end date based on whether current time is before or after 7:00 AM
    if (currentHour < 7) {
      // If it's before 7:00 AM, end date is yesterday at 6:59 AM
      tempEndDate = new Date(now.setDate(now.getDate() - 1));
    }
    tempEndDate.setHours(6, 59, 59, 999);
    const endDateFormatted = formatDate(tempEndDate);
    
    // Calculate start date based on end date
    let tempStartDate = new Date(tempEndDate);
    // Start date is 7 days before end date at 7:00 AM
    tempStartDate = new Date(tempStartDate.setDate(tempStartDate.getDate() - 7));
    tempStartDate.setHours(7, 0, 0, 0);
    const startDateFormatted = formatDate(tempStartDate);

    return { startDateFormatted, endDateFormatted };
  };

  const { startDateFormatted, endDateFormatted } = calculateDates();

  const [chartData, setChartData] = useState(null);
  const [start, setStart] = useState(startDateFormatted);
  const [end, setEnd] = useState(endDateFormatted);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await axios.get('https://api.thedailyaus.com.au/api/events/aggregate');
        const aggregateData = response.data;

        const convertDateToISO = (dateStr) => {
          const [day, month, year] = dateStr.split('-');
          return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`; // Converts to YYYY-MM-DD
        };

        const uniqueDates = [...new Set(aggregateData.map(item => item._id.date))]
          .map(date => convertDateToISO(date))
          .filter(date => date >= start && date <= end);
        const eventTypes = ['page_view', 'game_start', 'game_loss', 'game_win'];

        const datasets = eventTypes.map(type => ({
          label: type,
          data: uniqueDates.map(date => {
            const item = aggregateData.find(item => convertDateToISO(item._id.date) === date && item._id.eventType === type);
            return item ? item.count : 0;
          }),
          backgroundColor: generateBackgroundColor(type),
        }));

        setChartData({
          labels: uniqueDates,
          datasets,
        });
      } catch (error) {
        console.error('Failed to fetch event data:', error);
      }
    };

    fetchEventData();
  }, [start, end]); // Depend on the state variables

  return (
    <div className="section">
      <h2>Analytics</h2>
      <div>
        <label>Start Date: </label>
        <input type="date" value={start} onChange={(e) => setStart(e.target.value)} />
        <label> End Date: </label>
        <input type="date" value={end} onChange={(e) => setEnd(e.target.value)} />
      </div>
      {chartData ? (
        <Bar data={chartData} options={chartOptions} />
      ) : (
        <p>Please select a date range to display the chart...</p>
      )}
    </div>
  );
};

const generateBackgroundColor = (eventType) => {
  switch (eventType) {
    case 'page_view':
      return '#D4CEFD';
    case 'game_start':
      return '#FFDD00';
    case 'game_loss':
      return '#FF4747';
    case 'game_win':
      return '#2CA430';
    default:
      return '#D4CEFD';
  }
};

const chartOptions = {
  plugins: {
    title: {
      display: true,
      text: 'Event Counts by Date',
      color: 'white'
    },
    legend: {
      labels: {
        color: 'white'
      }
    }
  },
  scales: {
    x: {
      stacked: false,
      ticks: {
        color: 'white'
      },
      grid: {
        color: 'rgba(255, 255, 255, 0.5)'
      }
    },
    y: {
      stacked: false,
      beginAtZero: true,
      ticks: {
        color: 'white'
      },
      grid: {
        color: 'rgba(255, 255, 255, 0.5)'
      }
    }
  }
};

export default EventsChart;
