import React from 'react';
import Logo from '../assets/TDA_Rubix-Cube.png';

function Navigation() {
    return (
        <nav className="navigation">
            <div className="menu">
                <div className="menuitem">
                    <a href="https://emojimashv2-production.up.railway.app/" target="_blank" rel="noreferrer">
                        <img className="logo" src={Logo} alt="TDA Rubix Cube" />
                    </a>
                    <h2>Picture This Scheduler</h2>
                </div>
                <div className="menuitem">
                    <button type="submit">Save</button>
                </div>
            </div>
        </nav>
    );
}


export default Navigation;
